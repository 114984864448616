
















import { tasksStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

interface BtnToggleOption {
  value: number | null;
  label: string;
}

@Component({ name: 'SelectorBtnToggle' })
export default class SelectorBtnToggle extends Vue {
  /**
   * Importance value (not level) to be displayed and updated by the component
   */
  @Prop({
    type: Number,
    default: null,
  }) readonly value!: number

  get btnToggleOptions (): BtnToggleOption[] {
    const options = tasksStore.getters.importanceOptions.map(
      (i): BtnToggleOption => ({
        value: i,
        label: `${i}`,
      }),
    )

    // add 'no importance' option
    options.unshift({ value: null, label: this.$t('common.no').toString() })

    return options
  }
}
